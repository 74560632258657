export default {
  en: {
    autocomplete: {
      placeholder: 'Where are you going?',
      searchMessage: 'Type to search...'
    },
    common: {
      apply: 'Apply',
      cancel: 'Cancel',
      checkIn: 'Check In',
      checkOut: 'Check Out',
    },
    adult: 'adult | adults',
    child: 'child | children',
    room: 'room | rooms',
    guest: 'guest | guests',
    night: 'night | nights',
    sms_error: {
      header: 'Something went wrong',
      message: 'Sorry, there was an error occured. Please, try again later'
    },
    errors: {
      destinationMissing: 'Please enter a destination to start searching.'
    },
  }
}