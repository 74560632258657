var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calendar-no-picker", style: _vm.cssVars },
    [
      _c(
        "date-range-picker",
        _vm._b(
          {
            ref: "noPicker",
            attrs: { "date-format": _vm.disablePriorDates },
            on: {
              toggle: _vm.onCalendarToggle,
              "start-selection": _vm.setFirstDate,
              "finish-selection": _vm.setEndDate,
            },
            scopedSlots: _vm._u([
              {
                key: "input",
                fn: function (picker) {
                  return [_c("div", {})]
                },
              },
              {
                key: "prev-month-btn",
                fn: function () {
                  return [_c("div", {})]
                },
                proxy: true,
              },
              {
                key: "next-month-btn",
                fn: function () {
                  return [_c("div", {})]
                },
                proxy: true,
              },
              {
                key: "prev-month-title",
                fn: function (slotProps) {
                  return [
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "month GFont_3_Extrabold flex-grow-1",
                          staticStyle: { "text-align": "left" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(slotProps.monthName) +
                              " " +
                              _vm._s(slotProps.year) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "next-month-title",
                fn: function (slotProps) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "month GFont_3_Extrabold flex-grow-1",
                            staticStyle: { "text-align": "left" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(slotProps.monthName) +
                                " " +
                                _vm._s(slotProps.year) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              flex: "1",
                              display: "flex",
                              "justify-content": "flex-end",
                              gap: "1rem",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "GFont_3_Extrabold px-5",
                                staticStyle: { "font-size": "24px !important" },
                                attrs: { tabindex: "0" },
                                on: { click: slotProps.prevMonthClick },
                              },
                              [_vm._v("<")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "mx-4 GFont_3_Extrabold",
                                staticStyle: { "font-size": "24px !important" },
                                attrs: { tabindex: "0" },
                                on: { click: slotProps.nextMonthClick },
                              },
                              [_vm._v(">")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c("div", { staticClass: "cal_footer" }, [
                      _c("div", { staticClass: "cal_footer_dates center" }, [
                        _vm.selectedDates.startDate
                          ? _c("span", { staticClass: "GFont_6" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("dateShort")(
                                      _vm.selectedDates.startDate
                                    )
                                  ) +
                                  " ->\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedDates.endDate &&
                        !_vm.state.rangeSelectionStarted
                          ? _c("span", { staticClass: "GFont_6" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("dateShort")(
                                      _vm.selectedDates.endDate
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedDates.startDate &&
                        _vm.selectedDates.endDate &&
                        !_vm.state.rangeSelectionStarted
                          ? _c("span", { staticClass: "GFont_6" }, [
                              _vm._v(
                                "\n              ( " +
                                  _vm._s(_vm.dayDiffNights) +
                                  " )\n            "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: { click: _vm.onSelectedDates },
                        },
                        [_vm._v("Done")]
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.selectedDates,
              callback: function ($$v) {
                _vm.selectedDates = $$v
              },
              expression: "selectedDates",
            },
          },
          "date-range-picker",
          _vm.options,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }