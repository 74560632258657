import { render, staticRenderFns } from "./observer.vue?vue&type=template&id=15027701&"
import script from "./observer.vue?vue&type=script&lang=js&"
export * from "./observer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/code/mobytrip-hotelbeds/frontend/node_modules/vue-loader/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15027701')) {
      api.createRecord('15027701', component.options)
    } else {
      api.reload('15027701', component.options)
    }
    module.hot.accept("./observer.vue?vue&type=template&id=15027701&", function () {
      api.rerender('15027701', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/vue-apps/components/observer.vue"
export default component.exports