var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-field", class: _vm.customClass }, [
    _c(
      "div",
      { staticClass: "form-field__inner" },
      [
        _c("div", { staticClass: "form-field__icon-cnt" }, [
          _c("svg", { staticClass: "form-field__icon icon icon-calendar" }, [
            _c("use", {
              attrs: { "xlink:href": "/static/images/sprite.svg#calendar" },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "date-range-picker",
          _vm._b(
            {
              ref: "picker",
              attrs: {
                "date-format": _vm.disablePriorDates,
                "control-container-class": "form-field__field",
              },
              on: {
                "start-selection": _vm.setFirstDate,
                "finish-selection": _vm.setEndDate,
                update: _vm.onCalendarUpdate,
                toggle: _vm.onCalendarToggle,
              },
              scopedSlots: _vm._u([
                {
                  key: "input",
                  fn: function (picker) {
                    return [
                      _vm.displayLegend
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "form-field__label form-field__label_active",
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("common.checkIn")) +
                                  " - " +
                                  _vm._s(_vm.$t("common.checkOut")) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-field__text" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm._f("date")(picker.startDate)) +
                            " - " +
                            _vm._s(_vm._f("date")(picker.endDate)) +
                            "\n                "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.selectedDates,
                callback: function ($$v) {
                  _vm.selectedDates = $$v
                },
                expression: "selectedDates",
              },
            },
            "date-range-picker",
            _vm.options,
            false
          )
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }