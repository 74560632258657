<script>

import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { submitLogDL_GA_plus_DB } from '../../utils/tracking';

import messages from '../common/messages';
import clickOutside from '../directives/click-outside';
import modalsMixin from '../mixins/modalsMixin';

import AccommodationInput from '../components/AccommodationInput.vue'; // big
import Calendar from '../components/Calendar.vue';
import CalendarWithoutPicker from '../components/CalendarWithoutPicker.vue';
import Overlay from '../components/layout/Overlay.vue';
import SearchAutocomplete from '../components/SearchAutocomplete.vue';
import formatDate from '../filters/format-date';
import CookieConsentModal from '../components/CookieConsentModal.vue';

import { readDataFromContext } from '../../utils/utils.js';


// // ToDo move to a single place
const URL_DATE_FORMAT = 'YYYY-MM-DD';


export default {
    components: {
        AccommodationInput,
        Calendar,
        CalendarWithoutPicker,
        Overlay,
        SearchAutocomplete,
        CookieConsentModal
    },
    delimiters: ['<%', '%>'],
    directives: {
        'click-outside': clickOutside
    },
    filters: {
        formatDate: formatDate,
    },
    i18n: new VueI18n({
        locale: 'en',
        messages
    }),
    mixins: [
        modalsMixin,
    ],
    data() {
        return {
            displayCookieConsentModal: undefined,
            selected: {
                destination: null,
                checkIn: moment().add(1, 'days'),
                checkOut: moment().add(2, 'days'),
                rooms: [{
                    'adults': 2,
                    'children': []
                }],
            },
            state: {
                accommodationDropdownOpen: false,
            },
            topDestinations: [],
            partners: [],
        }
    },
    methods: {
        openDestinationOverlay() {
            this.openModal('destination-overlay');
            this.$nextTick(() => {
                this.$refs['autocomplete-mobile'].focus();
            });
        },
        onSearchResultSelect(result) {
            this.selected.destination = result;
            this.closeModal('destination-overlay');
        },
        onDatesChange(dates) {
            this.selected.checkIn = moment(dates.startDate);
            this.selected.checkOut = moment(dates.endDate);
            this.closeModal('calendar-overlay');
        },
        closeAccommodationDropdown() {
            this.state.accommodationDropdownOpen = false;
        },
        onAccommodationChange(accommodation) {
            this.selected.rooms = accommodation;
            if(this.state.accommodationDropdownOpen) {
                this.state.accommodationDropdownOpen = false;
            }
            this.closeModal('accommodation-overlay');
        },
        // buildSRPLink(destination) {
        //     let params = {
        //         region_id: destination.id,
        //         check_in: this.selected.checkIn.format(URL_DATE_FORMAT),
        //         check_out: this.selected.checkOut.format(URL_DATE_FORMAT),
        //         rooms: this.selected.rooms.length,
        //     };
        //     for (let i = 0; i < params.rooms; i++) {
        //         params[`room${i}_adults`] = this.selected.rooms[i].adults;
        //         params[`room${i}_children`] = this.selected.rooms[i].children.length;
        //         if(this.selected.rooms[i].children.length) {
        //           params[`room${i}_children_ages`] = this.selected.rooms[i].children.join(',');
        //         }
        //     }
        //     let urlParams = new URLSearchParams({
        //         ...params,
        //     });
        //     return `/search/?${urlParams.toString()}`;
        // },
        onMobileSlideClick(url) {
            window.location = url;
        }
    },
    computed: {
        checkInDate() {
            return this.selected.checkIn.toDate();
        },
        checkOutDate() {
            return this.selected.checkOut.toDate();
        },
        checkInInputValue() {
            return this.selected.checkIn.format(URL_DATE_FORMAT)
        },
        checkOutInputValue() {
            return this.selected.checkOut.format(URL_DATE_FORMAT)
        },
        accommodation() {
            return {
                roomsTotalNum: this.selected.rooms.length,
                guestsTotalNum: this.selected.rooms.reduce(
                    (guestsNum, room) => Number(guestsNum) + Number(room.adults) + Number(room.children.length), 0
                )
            }
        },
        topDestinationsWithLinks() {
            return this.topDestinations.map(destination => {
                return {
                    ...destination,
                    url: this.buildSRPLink(destination)
                }
            });
        }
    },
    watch: {
    },

    beforeMount() {
        // this.topDestinations = readDataFromContext('#top_destinations');
        this.partners = readDataFromContext('#partners');
    },
};
</script>
