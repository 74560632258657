import { render, staticRenderFns } from "./SearchAutocomplete.vue?vue&type=template&id=016a358a&"
import script from "./SearchAutocomplete.vue?vue&type=script&lang=js&"
export * from "./SearchAutocomplete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/code/mobytrip-hotelbeds/frontend/node_modules/vue-loader/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('016a358a')) {
      api.createRecord('016a358a', component.options)
    } else {
      api.reload('016a358a', component.options)
    }
    module.hot.accept("./SearchAutocomplete.vue?vue&type=template&id=016a358a&", function () {
      api.rerender('016a358a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/vue-apps/components/SearchAutocomplete.vue"
export default component.exports