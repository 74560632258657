<template>
  <table class="table-condensed">
    <thead>
    <tr>
      <th
        :colspan="showWeekNumbers ? 8 : 7"
      >
        <div v-if="showDropdowns">
          <select v-model="month" class="monthselect">
            <option v-for="(m, idx) in months" :key="idx" :value="m.value + 1" :disabled="!m.enabled">{{ m.label }}</option>
          </select>
          <input ref="yearSelect" type="number" v-model="year" @blur="checkYear" class="yearselect"/>
        </div>
        <slot name="month-title" v-bind="{monthName:monthName, year:year, nextMonthClick:nextMonthClick, prevMonthClick:prevMonthClick}" v-else>
          <div class="calendar-selector">
            <div class="calendar-selector__prev prev available" @click="prevMonthClick" tabindex="0"><span/></div>
            <div class="calendar-selector__month month">
              {{ monthName }} {{ year }}
            </div>
            <div class="calendar-selector__next next available" @click="nextMonthClick" tabindex="0"><span/></div>
          </div>
        </slot>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th v-if="showWeekNumbers" class="week">{{ locale.weekLabel }}</th>
      <th v-for="(weekDay, idx) in locale.daysOfWeek" :key="idx">{{ weekDay }}</th>
    </tr>
    <tr
      v-for="(dateRow, index) in calendar"
      :key="index"
    >
      <td v-if="showWeekNumbers && (index%7 || index===0)" class="week">
        {{ $dateUtil.weekNumber(dateRow[0]) }}
      </td>
      <td
        v-for="(date, idx) in dateRow"
        :class="dayClass(date)"
        @click="$emit('dateClick', date)"
        @mouseover="$emit('hoverDate', date)"
        :key="idx"
        :ref="dayRef(date)"
        :data-date="date.toISOString().substring(0, 10)"
      >
        <slot name="date-slot" :date="date">
          {{ date.getDate() }}
        </slot>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import dateUtilMixin from './dateUtilMixin'

export default {
  mixins: [dateUtilMixin],
  name: 'calendar',
  props: {
    monthDate: Date,
    localeData: Object,
    start: Date,
    end: Date,
    minDate: Date,
    maxDate: Date,
    showDropdowns: {
      type: Boolean,
      default: false,
    },
    showWeekNumbers: {
      type: Boolean,
      default: false,
    },
    dateFormat: {
      type: Function,
      default: null
    }
  },
  data () {
    let currentMonthDate = this.monthDate || this.start || new Date()
    return {
      currentMonthDate,
      year_text: currentMonthDate.getFullYear(),
    }
  },
  mounted(){
  },
  methods: {
    prevMonthClick () {
      this.changeMonthDate(this.$dateUtil.prevMonth(this.currentMonthDate))
    },
    nextMonthClick () {
      this.changeMonthDate(this.$dateUtil.nextMonth(this.currentMonthDate))
    },
    changeMonthDate (date, emit = true) {
      let year_month = this.$dateUtil.yearMonth(this.currentMonthDate)
      this.currentMonthDate = this.$dateUtil.validateDateRange(date, this.minDate, this.maxDate)
      // console.info(date, this.currentMonthDate)
      if (emit && year_month !== this.$dateUtil.yearMonth(this.currentMonthDate)) {
        this.$emit('change-month', {
          month: this.currentMonthDate.getMonth() + 1,
          year: this.currentMonthDate.getFullYear(),
        })
      }
      this.checkYear()
    },
    dayRef(date){
      let dayClasses = this.dayClass(date);
      if(dayClasses['start-date']){
        return "cal-selectedStartDay"
      }
    },
    dayClass (date) {
      let dt = new Date(date)
      dt.setHours(0, 0, 0, 0)
      let start = new Date(this.start)
      start.setHours(0, 0, 0, 0)
      let end = new Date(this.end)
      end.setHours(0, 0, 0, 0)

      let dt_min_compare = new Date(dt);
      dt_min_compare.setHours(23, 59, 59, 999)

      let classes = {
        off: date.getMonth() + 1 !== this.month,
        weekend: date.getDay() === 6 || date.getDay() === 0,
        today: dt.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0),
        active: dt.setHours(0, 0, 0, 0) == new Date(this.start).setHours(0, 0, 0, 0) || dt.setHours(0, 0, 0, 0) == new Date(this.end).setHours(0, 0, 0, 0),
        'in-range': dt >= start && dt <= end,
        'start-date': dt.getTime() === start.getTime(),
        'end-date': dt.getTime() === end.getTime(),
        disabled: (this.minDate && dt_min_compare.getTime() < this.minDate.getTime())
          || (this.maxDate && dt.getTime() > this.maxDate.getTime()),
      }
      return this.dateFormat ? this.dateFormat(classes, date) : classes

    },
    checkYear () {
      if (this.$refs.yearSelect !== document.activeElement) {
        this.$nextTick(() => {
          this.year_text = this.monthDate.getFullYear()
        })
      }
    }
  },
  computed: {
    activeDayElement() {
      return this.$refs['cal-selectedStartDay'] ? this.$refs['cal-selectedStartDay'][0] : undefined
    },
    monthName () {
      return this.locale.monthNames[this.currentMonthDate.getMonth()]
    },
    year: {
      get () {
        //return this.currentMonthDate.getFullYear()
        return this.year_text
      },
      set (value) {
        this.year_text = value
        let newDate = this.$dateUtil.validateDateRange(new Date(value, this.month, 1), this.minDate, this.maxDate)
        if (this.$dateUtil.isValidDate(newDate)) {
          this.$emit('change-month', {
            month: newDate.getMonth(),
            year: newDate.getFullYear(),
          });
        }
      }
    },
    month: {
      get () {
        return this.currentMonthDate.getMonth() + 1
      },
      set (value) {
        let newDate = this.$dateUtil.validateDateRange(new Date(this.year, value - 1, 1), this.minDate, this.maxDate)

        this.$emit('change-month', {
          month: newDate.getMonth() + 1,
          year: newDate.getFullYear(),
        });
      }
    },
    calendar () {
      let month = this.month
      let year = this.currentMonthDate.getFullYear()
      let firstDay = new Date(year, month - 1, 1)
      let lastMonth = this.$dateUtil.prevMonth(firstDay).getMonth() + 1
      let lastYear = this.$dateUtil.prevMonth(firstDay).getFullYear()
      let daysInLastMonth = new Date(lastYear, month - 1, 0).getDate()

      let dayOfWeek = firstDay.getDay()

      let calendar = []

      for (let i = 0; i < 6; i++) {
        calendar[i] = [];
      }

      let startDay = daysInLastMonth - dayOfWeek + this.locale.firstDay + 1
      if (startDay > daysInLastMonth)
        startDay -= 7

      if (dayOfWeek === this.locale.firstDay)
        startDay = daysInLastMonth - 6;

      let curDate = new Date(lastYear, lastMonth - 1, startDay, 12, 0, 0);
      for (let i = 0, col = 0, row = 0; i < 6 * 7; i++, col++, curDate.setDate(curDate.getDate() + 1)) {
        if (i > 0 && col % 7 === 0) {
          col = 0;
          row++;
        }
        calendar[row][col] = new Date(curDate.getTime())
      }

      return calendar
    },
    months () {
      return this.locale.monthNames.map((m, idx) => ({
        label: m,
        value: idx,
        enabled:
          (!this.maxDate || (this.maxDate >= new Date(this.year, idx, 1))) &&
          (!this.minDate || (this.minDate <= new Date(this.year, idx + 1, 0)))
      }));
    },
    locale () {
      return this.$dateUtil.localeData(this.localeData)
    }
  },
  watch: {
    monthDate (value) {
      if (this.currentMonthDate.getTime() !== value.getTime()) {
        this.changeMonthDate(value, false)
      }
    }
  }
}
</script>
