var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-dropdown" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.blur,
            expression: "blur",
          },
        ],
        staticClass: "form-field",
        class: {
          "search-dropdown__input-container_open":
            _vm.state.editing && _vm.results.length,
        },
        on: { click: _vm.onInputClick },
      },
      [
        _c("div", { staticClass: "form-field__inner" }, [
          _c(
            "div",
            { staticClass: "form-field__icon-cnt" },
            [
              _vm.state.loading
                ? _c(
                    "svg",
                    {
                      staticClass:
                        "form-field__icon icon icon-loading _loading",
                    },
                    [
                      _c("use", {
                        attrs: {
                          "xlink:href": "/static/images/sprite.svg#loading",
                        },
                      }),
                    ]
                  )
                : _vm.selectedResult
                ? [
                    _vm.selectedResult.type == "hotel"
                      ? _c(
                          "svg",
                          { staticClass: "form-field__icon icon icon-hotel" },
                          [
                            _c("use", {
                              attrs: {
                                "xlink:href": "/static/images/sprite.svg#hotel",
                              },
                            }),
                          ]
                        )
                      : _c(
                          "svg",
                          {
                            staticClass: "form-field__icon icon icon-location",
                          },
                          [
                            _c("use", {
                              attrs: {
                                "xlink:href":
                                  "/static/images/sprite.svg#location",
                              },
                            }),
                          ]
                        ),
                  ]
                : _c(
                    "svg",
                    { staticClass: "form-field__icon icon icon-location" },
                    [
                      _c("use", {
                        attrs: {
                          "xlink:href": "/static/images/sprite.svg#location",
                        },
                      }),
                    ]
                  ),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-field__field" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.models.query,
                  expression: "models.query",
                  modifiers: { trim: true },
                },
              ],
              ref: "input",
              staticClass: "form-field__input form-field__input_close",
              attrs: { type: "text", placeholder: " " },
              domProps: { value: _vm.models.query },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.models, "query", $event.target.value.trim())
                  },
                  _vm.onInput,
                ],
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _vm._v(" "),
            _c("label", { staticClass: "form-field__label" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.placeholder) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _vm.state.editing && _vm.models.query
              ? _c(
                  "svg",
                  {
                    staticClass: "form-field__close icon icon-close",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.clearInput($event)
                      },
                    },
                  },
                  [
                    _c("use", {
                      attrs: {
                        "xlink:href": "/static/images/sprite.svg#close",
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm._t("errors"),
        _vm._v(" "),
        _c("transition", { attrs: { name: "slide-down", appear: "" } }, [
          _vm.state.editing && _vm.results.length
            ? _c("div", { staticClass: "search-dropdown__results" }, [
                _c(
                  "ul",
                  { staticClass: "search-dropdown-list" },
                  [
                    _vm.state.loading
                      ? _vm._l(5, function (n) {
                          return _c(
                            "li",
                            { staticClass: "search-dropdown-item" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "search-dropdown-item__icon-cnt",
                                },
                                [
                                  _c("div", {
                                    staticClass: "icon skeleton skeleton_block",
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "search-dropdown-item__content",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "search-dropdown-item__header",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "search-dropdown-item__name",
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "skeleton skeleton_block",
                                            staticStyle: { width: "50%" },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "search-dropdown-item__description",
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "skeleton skeleton_block",
                                        staticStyle: { width: "70%" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        })
                      : _vm._l(_vm.convertedResults, function (result) {
                          return _c(
                            "li",
                            {
                              staticClass: "search-dropdown-item",
                              on: {
                                mousedown: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onSearchResultSelect(result)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "search-dropdown-item__icon-cnt",
                                },
                                [
                                  result.type == "hotel"
                                    ? _c(
                                        "svg",
                                        { staticClass: "icon icon-hotel" },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href":
                                                "/static/images/sprite.svg#hotel",
                                            },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "svg",
                                        { staticClass: "icon icon-location" },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href":
                                                "/static/images/sprite.svg#location",
                                            },
                                          }),
                                        ]
                                      ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "search-dropdown-item__content",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "search-dropdown-item__header",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "search-dropdown-item__name",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(result.name) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "search-dropdown-item__description",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(result.description) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }