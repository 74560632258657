<template>
    <div
        v-if="isOpen"
        class="overlay"
        :class="{
            'overlay_full': position == 'full',
            'overlay_bottom': position == 'bottom'
        }"
    >
        <div class="overlay__header">
            <svg
                @click="close"
                class="overlay__close icon icon-close"
                :class="{
                    'overlay__close_left': closeIconPosition == 'left',
                    'overlay__close_right': closeIconPosition == 'right'
                }"
            >
                <use xlink:href="/static/images/sprite.svg#close"></use>
            </svg>
            <div class="overlay__title">
                <slot name="title">
                </slot>
            </div>
        </div>
        <div class="overlay__body">
            <slot name="body">
            </slot>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        position: {
            type: String,
            default: 'full',
        },
        closeIconPosition: {
            type: String,
            default: 'right',
        }
    },
    computed: {
    },
    data () {
        return {
            isOpen: false,
        }
    },
    methods: {
        open() {
            document.querySelector('body').classList.add('overflow-prevent');
            // hack for srp, ToDo check for better solution
            // required for header overlays
            let $results = document.querySelector('.search-page__results');
            $results && $results.classList.add('overflow-hidden');
            this.isOpen = true;
        },
        close(event) {
            document.querySelector('body').classList.remove('overflow-prevent');
            let $results = document.querySelector('.search-page__results');
            $results && $results.classList.remove('overflow-hidden');
            this.isOpen = false;
            this.$emit('close');
        },
    },
    mounted() {
        this.$el.parentNode.removeChild(this.$el);
        document.body.appendChild(this.$el);
    }
}
  
</script>
