<template>
    <div class="search-dropdown">
        <div
            @click="onInputClick"
            class="form-field"
            :class="{'search-dropdown__input-container_open': state.editing && results.length}"
            v-click-outside="blur"
        >
            <div class="form-field__inner">
                <div class="form-field__icon-cnt">
                    <svg
                        v-if="state.loading"
                        class="form-field__icon icon icon-loading _loading"
                    >
                        <use xlink:href="/static/images/sprite.svg#loading"></use>
                    </svg>
                    <template v-else-if="selectedResult">
                        <svg
                            v-if="selectedResult.type == 'hotel'"
                            class="form-field__icon icon icon-hotel"
                        >
                            <use xlink:href="/static/images/sprite.svg#hotel"></use>
                        </svg>
                        <svg
                            v-else
                            class="form-field__icon icon icon-location"
                        >
                            <use xlink:href="/static/images/sprite.svg#location"></use>
                        </svg>
                    </template>
                    <svg
                        v-else
                        class="form-field__icon icon icon-location"
                    >
                        <use xlink:href="/static/images/sprite.svg#location"></use>
                    </svg>
                </div>
                <div class="form-field__field">
                    <input
                        ref="input"
                        type="text"
                        placeholder=" "
                        class="form-field__input form-field__input_close"
                        v-model.trim="models.query"
                        @input="onInput"
                    >
                    <label class="form-field__label">
                        {{ placeholder }}
                    </label>
                    <svg
                        v-if="state.editing && models.query"
                        class="form-field__close icon icon-close"
                        @click.stop="clearInput"
                    >
                        <use xlink:href="/static/images/sprite.svg#close"></use>
                    </svg>
                </div>
            </div>
            <slot name="errors">
            </slot>
            <transition name="slide-down" appear>
                <div
                    v-if="state.editing && results.length"
                    class="search-dropdown__results"
                >
                    <ul class="search-dropdown-list">
                        <template
                            v-if="state.loading"
                        >
                            <li
                                v-for="n in 5"
                                class="search-dropdown-item"
                            >
                                <div class="search-dropdown-item__icon-cnt">
                                    <div class="icon skeleton skeleton_block"></div>
                                </div>
                                <div class="search-dropdown-item__content">
                                    <div class="search-dropdown-item__header">
                                        <div class="search-dropdown-item__name">
                                            <div class="skeleton skeleton_block" style="width: 50%;"></div>
                                        </div>
                                    </div>
                                    <div class="search-dropdown-item__description">
                                        <div class="skeleton skeleton_block" style="width: 70%;"></div>
                                    </div>
                                </div>
                            </li>
                        </template>
                        <template
                            v-else
                        >
                            <li
                                v-for="result in convertedResults"
                                @mousedown.prevent="onSearchResultSelect(result)"
                                class="search-dropdown-item"
                            >
                                <div class="search-dropdown-item__icon-cnt">
                                    <svg
                                        v-if="result.type == 'hotel'"
                                        class="icon icon-hotel"
                                    >
                                        <use xlink:href="/static/images/sprite.svg#hotel"></use>
                                    </svg>
                                    <svg
                                        v-else
                                        class="icon icon-location"
                                    >
                                        <use xlink:href="/static/images/sprite.svg#location"></use>
                                    </svg>
                                </div>
                                <div class="search-dropdown-item__content">
                                    <div class="search-dropdown-item__header">
                                        <div class="search-dropdown-item__name">
                                            {{ result.name }}
                                        </div>
                                        <!-- <div
                                            v-if="result.tag"
                                            class="search-dropdown-item__type"
                                        >
                                            {{ result.tag }}
                                        </div> -->
                                    </div>
                                    <div class="search-dropdown-item__description">
                                        {{ result.description }}
                                    </div>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>

import debounce from 'lodash/debounce';
import VueGeolocation from 'vue-browser-geolocation';
import { v4 as uuidv4 } from 'uuid';

import Destination from '../api/destination';
import clickOutside from '../directives/click-outside';

import { readDataFromContext } from '../../utils/utils.js';


const SEARCH_DELAY = 500;
const GOOGLE_SEARCH_DELAY = 5000;

Vue.use(VueGeolocation);

export default {
    components: {
    },
    directives: {
        'click-outside': clickOutside
    },
    props: {
        initial: {
            type: Object,
            required: false,
        },
        placeholder: {
            type: String,
            default: 'Where are you going?',
        },
        // resultsVisible: {
        //   type: Boolean,
        //   default: false,
        // },
        // autofocus: {
        //   type: Boolean,
        //   default: false,
        // },
        // customClass: {
        //   type: Array,
        //   default() { return [] },
        // },
        // regionId: {
        //   type: String,
        //   default: '',
        // },
        regionsSize: {
            type: Number,
            default: 5,
        },
        hotelsSize: {
            type: Number,
            default: 5,
        },
        // grabFocus: {
        //   type: Boolean,
        //   default: false
        // },
        // windowWidth: {
        //   type: Number,
        //   required: false
        // }
    },
    data() {
        return {
            models: {
                query: '',
            },
            results: [],
            selectedResult: null,
            state: {
                requestID: null,
                performInitialSearch: false,
                editing: false,
                loading: false,
                //   geolocationFailed: false
            }
        }
    },
    computed: {
        convertedResults() {
            return this.results;
          // return this.results.map(result => {
          //   if(result.type == 'region') {
          //     return {
          //       ...result,
          //       type: result.type.replaceAll('_', ' '),
          //       description: result.name_full,
          //     }
          //   } else if(result.type == 'hotel') {
          //     return {
          //       ...result,
          //       description: result.full_address,
          //     }
          //   }
          //   else if(result.type == 'point') {
          //     return {
          //       ...result,
          //       description: result.address,
          //     }
          //   }
          // })
        }
    },
    methods: {
        focus() {
            this.models.query = '';
            this.$refs['input'].focus();
        },
        search(query) {
            let searchParams = {
                query: query,
                regions_size: this.regionsSize,
                hotels_size: this.hotelsSize,
            }
            let requestID = uuidv4();
            this.state.requestID = requestID;
            this.state.loading = true;
            Destination.search(searchParams)
            .then((response) => {
                if(requestID == this.state.requestID) {
                    this.results = response.data;
                }
            }).finally(() => {
                if(requestID == this.state.requestID) {
                    this.state.editing = true;
                    this.state.loading = false;
                    this.state.performInitialSearch = false;
                    this.state.requestID = null;
                }
            })
        },
        setSelectedResult(result) {
            this.selectedResult = result;
            this.models.query = result.name;
            if(result.type == 'hotel') {
                this.models.query = result.name;
            } else if(result.type == 'point') {
                this.models.query = result.description;
            } else {
                this.models.query = result.description;
            }
        },
        onInputClick() {
            this.state.editing = true;
            if(this.state.performInitialSearch && this.models.query) {
                this.search(this.models.query);
            }
            this.$refs['input'].focus();
        },
        onInput: debounce(function(event){
            let query = event.target.value;
            if(!query.length) {
                return;
            }
            // this.state.loading = true;
            this.search(this.models.query);
        }, SEARCH_DELAY),
        clearInput() {
            this.models.query = '';
            this.$refs['input'].focus();
        },
        blur(e) {
            this.state.editing = false;
        },
        // onBlur(e) {
        //   console.log('blur', e.target);
        //   this.state.editing = false;
        // },
        onSearchResultSelect(result) {
            this.setSelectedResult(result);
            this.$emit('result-selected', result);
            // submitLogEvent('success_new_destination', {destination_id: result.id});
            this.blur();
            this.$refs['input'].blur();
        }
    },
    beforeMount(){
        if(this.initial) {
            this.setSelectedResult(this.initial);
            this.state.performInitialSearch = true;
        }
        // let initialRegion = readDataFromContext('#initial-region');
        // if(initialRegion) {
        //   this.setSelectedResult(initialRegion);
        //   this.state.performInitialSearch = true;
        // }
  },
}
</script>
