var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c(
        "div",
        {
          staticClass: "overlay",
          class: {
            overlay_full: _vm.position == "full",
            overlay_bottom: _vm.position == "bottom",
          },
        },
        [
          _c("div", { staticClass: "overlay__header" }, [
            _c(
              "svg",
              {
                staticClass: "overlay__close icon icon-close",
                class: {
                  overlay__close_left: _vm.closeIconPosition == "left",
                  overlay__close_right: _vm.closeIconPosition == "right",
                },
                on: { click: _vm.close },
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "/static/images/sprite.svg#close" },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "overlay__title" }, [_vm._t("title")], 2),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "overlay__body" }, [_vm._t("body")], 2),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }