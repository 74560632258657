export default {
  methods: {
    openModal(modal) {
      this.$refs[modal].open();
    },
    closeModal(modal) {
      this.$refs[modal].close();
    },
    isModalOpen(modal) {
      return this.$refs[modal].isOpen;
    }
  }
}