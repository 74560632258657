var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ranges" }, [
    _vm.ranges
      ? _c(
          "ul",
          [
            _vm._l(_vm.listedRanges, function (range) {
              return _c(
                "li",
                {
                  key: range.label,
                  class: _vm.range_class(range),
                  attrs: { "data-range-key": range.label, tabindex: "0" },
                  on: {
                    click: function ($event) {
                      return _vm.clickRange(range.value)
                    },
                  },
                },
                [_vm._v(_vm._s(range.label) + "\n        ")]
              )
            }),
            _vm._v(" "),
            _vm.showCustomRangeLabel
              ? _c(
                  "li",
                  {
                    class: {
                      active: _vm.customRangeActive || !_vm.selectedRange,
                    },
                    attrs: { tabindex: "0" },
                    on: { click: _vm.clickCustomRange },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.localeData.customRangeLabel) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }