var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selfShow
    ? _c(
        "div",
        { staticClass: "consent-cookie-container" },
        [
          _c(
            "h2",
            { staticClass: "consent-cookie-container__title GFont_6_Bold" },
            [_vm._v("This website use cookies")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "consent-cookie-container__close-button",
              on: {
                click: function ($event) {
                  _vm.selfShow = false
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "16",
                    height: "16",
                    viewBox: "0 0 16 16",
                    fill: "none",
                    xmlns: "https://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M12 4L4 12",
                      stroke: "#1E1C2A",
                      "stroke-width": "1.75",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M4 4L12 12",
                      stroke: "#1E1C2A",
                      "stroke-width": "1.75",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "consent-cookie-container__description GFont_9" },
            [
              _vm._v(
                "\n            We use cookies to give you the best experience on our website and also for analytics purposes. \n            \n            "
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.expandedForm,
                      expression: "!expandedForm",
                    },
                  ],
                  staticClass: "adjust-settings",
                  on: { click: _vm.adjustSettings },
                },
                [_vm._v("Adjust settings")]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.cookieTypesArray, function (cookieType, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expandedForm,
                    expression: "expandedForm",
                  },
                ],
                key: index,
                staticClass: "input-container GFont_9",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.checkedCookieTypesArray,
                      expression: "checkedCookieTypesArray",
                    },
                  ],
                  attrs: { type: "checkbox", id: cookieType, name: cookieType },
                  domProps: {
                    value: cookieType,
                    checked: Array.isArray(_vm.checkedCookieTypesArray)
                      ? _vm._i(_vm.checkedCookieTypesArray, cookieType) > -1
                      : _vm.checkedCookieTypesArray,
                  },
                  on: {
                    click: _vm.clickedInCheckbox,
                    change: function ($event) {
                      var $$a = _vm.checkedCookieTypesArray,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = cookieType,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.checkedCookieTypesArray = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.checkedCookieTypesArray = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.checkedCookieTypesArray = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: cookieType } }, [
                  _vm._v(_vm._s(_vm.cookiesNameArray[index])),
                ]),
              ]
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "accept-decline-container GFont_8" }, [
            _c(
              "button",
              _vm._g(
                {
                  staticClass: "accept",
                  style: _vm.backgroundColorObjectSiteName,
                },
                _vm.atLeastOneCheckboxChecked
                  ? { click: _vm.acceptAll }
                  : { click: _vm.acceptAll }
              ),
              [_vm._v("Accept all")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expandedForm,
                    expression: "expandedForm",
                  },
                ],
                style: [
                  !_vm.atLeastOneCheckboxChecked
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer" },
                ],
                attrs: { disabled: !_vm.atLeastOneCheckboxChecked },
                on: { click: _vm.accept },
              },
              [_vm._v("Save selection")]
            ),
            _vm._v(" "),
            _c("button", { on: { click: _vm.declineAll } }, [_vm._v("Deny")]),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expandedForm,
                    expression: "expandedForm",
                  },
                ],
              },
              [_vm._v("No, adjust")]
            ),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }