var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "observer",
    {
      on: {
        "on-change": function (intersectionObserverEntry) {
          return _vm.toggleVisibility(intersectionObserverEntry)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "pax-editor" },
        [
          _vm._l(_vm.selectedAccommodation, function (selectedRoom, roomIndex) {
            return _c("div", { staticClass: "pax-editor__block" }, [
              _c("div", { staticClass: "pax-editor__row" }, [
                _c("div", { staticClass: "pax-editor__header" }, [
                  _vm._v(
                    "\n                    Room #" +
                      _vm._s(roomIndex + 1) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _vm.selectedAccommodation.length > 1 && roomIndex !== 0
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "pax-editor__room-modifier",
                          on: {
                            click: function ($event) {
                              return _vm.removeRoom(roomIndex)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Remove room\n                    "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pax-editor__row" }, [
                _c("div", { staticClass: "pax-editor__title" }, [
                  _vm._v("\n                    Adults\n                "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pax-editor__input input-counter" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input-counter__control",
                      attrs: {
                        disabled:
                          selectedRoom.adults === _vm.accommodation.adults.min,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeGuest(selectedRoom, "adult")
                        },
                      },
                    },
                    [
                      _c("svg", { staticClass: "icon icon-minus" }, [
                        _c("use", {
                          attrs: {
                            "xlink:href": "/static/images/sprite.svg#minus",
                          },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: selectedRoom.adults,
                        expression: "selectedRoom.adults",
                      },
                    ],
                    key: roomIndex,
                    staticClass: "input-counter__input",
                    attrs: {
                      min: _vm.accommodation.adults.min,
                      max: _vm.accommodation.adults.max,
                      type: "text",
                      readonly: "readonly",
                    },
                    domProps: { value: selectedRoom.adults },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(selectedRoom, "adults", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "input-counter__control",
                      attrs: {
                        disabled:
                          selectedRoom.adults === _vm.accommodation.adults.max,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addGuest(selectedRoom, "adult")
                        },
                      },
                    },
                    [
                      _c("svg", { staticClass: "icon icon-plus" }, [
                        _c("use", {
                          attrs: {
                            "xlink:href": "/static/images/sprite.svg#plus",
                          },
                        }),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pax-editor__row" }, [
                _c("div", { staticClass: "pax-editor__title" }, [
                  _vm._v("\n                    Children\n                "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pax-editor__input input-counter" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input-counter__control",
                      attrs: {
                        disabled:
                          selectedRoom.children.length ===
                          _vm.accommodation.children.min,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeGuest(selectedRoom, "child")
                        },
                      },
                    },
                    [
                      _c("svg", { staticClass: "icon icon-minus" }, [
                        _c("use", {
                          attrs: {
                            "xlink:href": "/static/images/sprite.svg#minus",
                          },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "input-counter__input",
                    attrs: {
                      min: _vm.accommodation.children.min,
                      max: _vm.accommodation.children.max,
                      type: "text",
                      readonly: "readonly",
                    },
                    domProps: { value: selectedRoom.children.length },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "input-counter__control",
                      attrs: {
                        disabled:
                          selectedRoom.children.length ===
                          _vm.accommodation.childrenMax,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addGuest(selectedRoom, "child")
                        },
                      },
                    },
                    [
                      _c("svg", { staticClass: "icon icon-plus" }, [
                        _c("use", {
                          attrs: {
                            "xlink:href": "/static/images/sprite.svg#plus",
                          },
                        }),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              selectedRoom.children.length
                ? _c(
                    "div",
                    { staticClass: "pax-editor__row pax-editor__row_ages" },
                    _vm._l(
                      selectedRoom.children,
                      function (childAge, ageIndex) {
                        return _c(
                          "div",
                          { key: ageIndex, staticClass: "pax-editor__age" },
                          [
                            _c("div", { staticClass: "pax-editor__title" }, [
                              _vm._v(
                                "\n                        Child " +
                                  _vm._s(ageIndex + 1) +
                                  " Age\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("v-select", {
                              staticClass:
                                "pax-editor__select select select_small",
                              attrs: {
                                searchable: false,
                                clearable: false,
                                options: _vm.accommodation.children.options,
                              },
                              model: {
                                value: selectedRoom.children[ageIndex],
                                callback: function ($$v) {
                                  _vm.$set(selectedRoom.children, ageIndex, $$v)
                                },
                                expression: "selectedRoom.children[ageIndex]",
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ])
          }),
          _vm._v(" "),
          _vm.selectedAccommodation.length < _vm.accommodation.rooms.max
            ? _c("div", { staticClass: "pax-editor__row" }, [
                _c(
                  "div",
                  {
                    staticClass: "pax-editor__room-modifier",
                    on: { click: _vm.addRoom },
                  },
                  [_vm._v("\n                Add another room\n            ")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "pax-editor__row" }, [
            _c(
              "button",
              {
                staticClass: "btn btn_primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.doneClicked($event)
                  },
                },
              },
              [_vm._v("\n                Done\n            ")]
            ),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }