<template>
    <div
        class="form-field"
        :class="customClass"
    >
        <div class="form-field__inner">
            <div class="form-field__icon-cnt">
                <svg class="form-field__icon icon icon-calendar">
                    <use xlink:href="/static/images/sprite.svg#calendar"></use>
                </svg>
            </div>
            <date-range-picker ref="picker"
                v-bind="options" v-model="selectedDates"
                :date-format="disablePriorDates"
                :control-container-class="'form-field__field'"
                @start-selection="setFirstDate"
                @finish-selection="setEndDate"
                @update="onCalendarUpdate"
                @toggle="onCalendarToggle"
            >
                <template v-slot:input="picker">
                    <div class="form-field__label form-field__label_active"    v-if="displayLegend">
                        {{ $t('common.checkIn') }} - {{ $t('common.checkOut') }}
                    </div>
                    <div class="form-field__text">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                    </div>
                </template>
            </date-range-picker>
        </div>
    </div>
</template>
<script>

//import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

// import VueI18n from 'vue-i18n';
import DateRangePicker from '../../../vendor/js/libs/vue2-daterange-picker/src/index.js';

import { submitLogDL_GA_plus_DB } from '../../utils/tracking';

import messages from '../common/messages';
import formatDate from '../filters/format-date';


export default {
    components: {
        DateRangePicker
    },
    i18n: new VueI18n({
        locale: 'en',
        messages,
    }),
    props: {
        startDate: {
            type: Date,
        },
        endDate: {
            type: Date,
        },
        displayLegend: {
            type: Boolean,
            default: true
        },
        customClass: {
            type: Object,
        }
    },
    // mounted(){
    //     console.log("mounted CALENDAR");
    // },
    // destroyed(){
    //     console.log("destroyed CALENDAR");
    // },
    methods: {
        /*disabledDatesBeforeDate(dateArg) {
            const dateArgStr = dateArg.toISOString().split('T')[0]

            var nodeDates = document.querySelectorAll('[data-date]')
            for (let i = 0; i < nodeDates.length; i++) {
                var nodeCalendar = nodeDates[i];
                if (new Date(nodeCalendar.getAttribute('data-date')) < dateArg) {
                    nodeCalendar.classList.add('disabled')
                    nodeCalendar.setAttribute('data-date-before', true)
                    // console.log(`${i} - ${nodeCalendar.getAttribute('data-date')} - data-date-before`);
                }
                else if (new Date(nodeCalendar.getAttribute('data-date')) == dateArg) {

                    nodeCalendar.setAttribute('data-date-selected', true)

                }
                else {
                    nodeCalendar.setAttribute('data-date-before', false)
                }
            }


            const nodeDateArg = document.querySelectorAll(`[data-date="${dateArgStr}"]`);
            for (let i = 0; i < nodeDateArg.length; i++) {
                var nda = nodeDateArg[i];
                nda.setAttribute("data-start", "")
            }
        },*/
        setFirstDate(dateSelected) {
            this.state.rangeSelectionStarted = true;
            this.state.selectedStartDate = dateSelected;
        },
        disablePriorDates (classes, date) {
            if (!classes.disabled) {
                if(this.state.rangeSelectionStarted){
                    classes.disabled = date.getTime() <= this.state.selectedStartDate.getTime()
                }
            }
            //console.log(classes);
            return classes
        },
        setEndDate(dateSelected) {
            this.state.rangeSelectionStarted = false;
            this.selectedDates.startDate = this.state.selectedStartDate;
            this.selectedDates.endDate = dateSelected;
        },
        onCalendarUpdate(selectedDates, endDate){
            this.$emit('dates-changed', selectedDates);
        },
        onCalendarToggle(isOpen){
            // console.log({isOpen});
            if(isOpen){
                // console.log("pdp_openCalendarModal");
                submitLogDL_GA_plus_DB('pdp_openCalendarModal', 'pdp')
            //    if(!this.state.rangeSelectionStarted){
            //        this.$emit('dates-changed', this.selectedDates);
            //    }
            }else{
                // console.log("pdp_closeCalendarModal");
                submitLogDL_GA_plus_DB('pdp_closeCalendarModal', 'pdp')

            }
        }
    },
    data() {
        return {
            drpKey: 0,
            state: {
                rangeSelectionStarted:false,
                selectedStartDate: undefined
            },
            options: {
                minDate: new Date(),
                opens: 'right',
                appendToBody: true,
                ranges: false,
                autoApply: true,
                localeData: {
                    direction: 'ltr',
                    format: 'mmm dd',
                    // separator: ' - ',
                    // applyLabel: 'Apply chiche',
                    // cancelLabel: 'Cancel chiche',
                    // weekLabel: 'W',
                    // customRangeLabel: 'Custom Range',
                    // daysOfWeek: ['a', 'v', 'c', 'd', 'Thu', 'Fri', 'Sat'],
                    // monthNames: ['Jan', 'x1', 'Mar', 'Apr', 'May', 'x', 'Jul', 'Aug', 'eee', 'x3', 'Nov', 'Dec'],
                    // firstDay: 0
                }
            },
            selectedDates: {
                startDate: this.startDate,
                endDate: this.endDate,
            },
        }
    },
    computed: {
        formattedCalendarDates() {
            return formatDate(this.selectedDates.startDate, 'MM/DD/YYYY')
                + ' - ' +
                formatDate(this.selectedDates.endDate, 'MMM DD')
                ;
        }
    },
    filters: {
        date(val) {
            return val ? formatDate(val,'MMM DD') : ''
        }
    },
    watch: {
        //selectedDates: function (n, o) {
        //    this.$emit('dates-changed', n)
        //}
    },
}
</script>
