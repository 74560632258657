export default {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
            if (
                document.body.contains(event.target) &&
                !(el === event.target || el.contains(event.target))
            ) {
                binding.value(event);
            }
        };
        document.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.removeEventListener('click', el.clickOutsideEvent);
    },
}
