<template>
    <observer @on-change="(intersectionObserverEntry) => toggleVisibility(intersectionObserverEntry)">
        <div class="pax-editor">
            <div
                v-for="(selectedRoom, roomIndex) of selectedAccommodation"
                class="pax-editor__block"
            >
                <div class="pax-editor__row">
                    <div class="pax-editor__header">
                        Room #{{ roomIndex + 1 }}
                    </div>
                    <div
                        v-if="selectedAccommodation.length > 1 && roomIndex !== 0"
                    >
                        <div
                            class="pax-editor__room-modifier"
                            @click="removeRoom(roomIndex)"
                        >
                            Remove room
                        </div>
                    </div>
                </div>
                <div class="pax-editor__row">
                    <div class="pax-editor__title">
                        Adults
                    </div>
                    <div class="pax-editor__input input-counter">
                        <div
                            :disabled="selectedRoom.adults === accommodation.adults.min"
                            @click="removeGuest(selectedRoom, 'adult')"
                            class="input-counter__control"
                        >
                            <svg class="icon icon-minus">
                                <use xlink:href="/static/images/sprite.svg#minus"></use>
                            </svg>
                        </div>
                        <input
                            v-model="selectedRoom.adults"
                            :min="accommodation.adults.min"
                            :max="accommodation.adults.max"
                            :key="roomIndex"
                            type="text"
                            readonly="readonly"
                            class="input-counter__input"
                        >
                        <div
                            :disabled="selectedRoom.adults === accommodation.adults.max"
                            @click="addGuest(selectedRoom, 'adult')"
                            class="input-counter__control"
                        >
                            <svg class="icon icon-plus">
                                <use xlink:href="/static/images/sprite.svg#plus"></use>
                            </svg>
                        </div>
                    </div>
                </div>

                <div class="pax-editor__row">
                    <div class="pax-editor__title">
                        Children
                    </div>
                    <div class="pax-editor__input input-counter">
                        <div
                            :disabled="selectedRoom.children.length === accommodation.children.min"
                            @click="removeGuest(selectedRoom, 'child')"
                            class="input-counter__control"
                        >
                            <svg class="icon icon-minus">
                                <use xlink:href="/static/images/sprite.svg#minus"></use>
                            </svg>
                        </div>
                        <input
                            v-bind:value="selectedRoom.children.length"
                            :min="accommodation.children.min"
                            :max="accommodation.children.max"
                            type="text"
                            readonly="readonly"
                            class="input-counter__input"
                        >
                        <div
                            :disabled="selectedRoom.children.length === accommodation.childrenMax"
                            @click="addGuest(selectedRoom, 'child')"
                            class="input-counter__control"
                        >
                            <svg class="icon icon-plus">
                                <use xlink:href="/static/images/sprite.svg#plus"></use>
                            </svg>
                        </div>
                    </div>
                </div>
                <div
                    v-if="selectedRoom.children.length"
                    class="pax-editor__row pax-editor__row_ages"
                >
                    <div
                        v-for="(childAge, ageIndex) in selectedRoom.children"
                        :key="ageIndex"
                        class="pax-editor__age"
                    >
                        <div class="pax-editor__title">
                            Child {{ ageIndex + 1 }} Age
                        </div>
                        <v-select
                            class="pax-editor__select select select_small"
                            v-model="selectedRoom.children[ageIndex]"
                            :searchable="false"
                            :clearable="false"
                            :options="accommodation.children.options"
                        >
                        </v-select>
                    </div>
                </div>
            </div>
            <div
                v-if="selectedAccommodation.length < accommodation.rooms.max"
                class="pax-editor__row"
            >
                <div
                    class="pax-editor__room-modifier"
                    @click="addRoom"
                >
                    Add another room
                </div>
            </div>
            <div class="pax-editor__row">
                <button
                    type="button"
                    class="btn btn_primary"
                    @click.stop="doneClicked"
                >
                    Done
                </button>
            </div>
        </div>
    </observer>
</template>
<script>

import 'vue-select/dist/vue-select.css'; // check if we can move it to styles

// import VueI18n from 'vue-i18n';
import vSelect from "vue-select";

import messages from '../common/messages';
import observer from "./observer.vue";


export default {
    components: {
        observer,
        vSelect,
    },
    store: window.STORE,
    props: {
        paxes: {
            type: Array,
            required: true
        },
    },
    i18n: new VueI18n({
        locale: 'en',
        messages,
    }),
    data() {
        return {
            GUEST_TYPES: {
                adult: 'adult',
                child: 'child',
            },
            //isDesktop: true,
            isVisible: false,
            // isChanged: false,
            accommodation: {
                rooms: {
                    min: 1,
                    max: 10,
                },
                adults: {
                    min: 1,
                    max: 5,
                },
                children: {
                    min: 0,
                    max: 10,
                    defaultAge: 0,
                    maxAge: 12,
                    options: Array.from({length: 12}, (_, i) => i + 1),
                }
            },
            originalPaxes: undefined,
            selectedAccommodation: [{ adults: 2, children: [] }],
        }
    },
    mounted(){
        if(this.paxes.length > 0){
            // Hack to deep copy the pax data without any of the vuex reactivity tagging along
            this.selectedAccommodation = JSON.parse(JSON.stringify(this.paxes));
            this.originalPaxes = JSON.stringify(this.paxes);
        }
    },
    computed: {
        paxesChanged(){
            return this.originalPaxes != JSON.stringify(this.selectedAccommodation)
        }
    },
    methods: {
        toggleVisibility(intersectionObserverEntry){
            if(intersectionObserverEntry.intersectionRatio == 0 && this.paxesChanged){
                this.doneClicked();
                //if(this.isChanged){
                    //this.$emit('accommodation-changed', this.selectedAccommodation)
                //}
                //this.$emit('done-clicked');
                //this.isVisible = false;
            }
        },
        doneClicked(){
            let paxesChanged = this.paxesChanged;
            if(this.paxesChanged){
                this.originalPaxes = JSON.stringify(this.selectedAccommodation)
            }
            this.$emit('finished', this.selectedAccommodation, paxesChanged);
        },
        addRoom() {
            if (this.selectedAccommodation.length < this.accommodation.rooms.max) {
                this.$set(this.selectedAccommodation, this.selectedAccommodation.length, { adults: 2, children: [] })
            }
            this.$emit('accommodation-changed', this.selectedAccommodation);
        },
        removeRoom(roomIndex) {
            // if (this.selectedAccommodation.length > this.accommodation.rooms.min) {
            //     this.selectedAccommodation.pop();
            //     //this.selectedAccommodation.splice(newLength)
            // }

            this.selectedAccommodation.splice(roomIndex, 1);
            this.$emit('accommodation-changed', this.selectedAccommodation);
        },
        addGuest(room, guestType) {
            if (guestType == this.GUEST_TYPES.adult && room.adults < this.accommodation.adults.max) {
                room.adults++;
            } else if (guestType == this.GUEST_TYPES.child && room.children.length < this.accommodation.children.max) {
                room.children.push(this.accommodation.children.defaultAge);
            }
            this.$emit('accommodation-changed', this.selectedAccommodation)
        },
        removeGuest(room, guestType) {
            if (guestType == this.GUEST_TYPES.adult && room.adults > this.accommodation.adults.min) {
                room.adults--;
            } else if (guestType == this.GUEST_TYPES.child && room.children.length > this.accommodation.children.min) {
                room.children.pop();
            }
            this.$emit('accommodation-changed', this.selectedAccommodation)
        },
    },
    /*watch: {
        selectedAccommodation:
        {
            handler: function (n, o) {
                this.isChanged = true;
            },
            deep: true
        }
    }*/
}
</script>
