import { API } from './_common'

export default {
  search(params) {
    return API.get('/apis/regions/search/', {params: params});
  },
  filters(id) {
    return API.get(`/apis/regions/${id}/filters/`);
  },
  externalLinks(params) {
    return API.get('/apis/external-links/', {params: params});
  }
}
