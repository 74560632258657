var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table-condensed" }, [
    _c("thead", [
      _c("tr", [
        _c(
          "th",
          { attrs: { colspan: _vm.showWeekNumbers ? 8 : 7 } },
          [
            _vm.showDropdowns
              ? _c("div", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.month,
                          expression: "month",
                        },
                      ],
                      staticClass: "monthselect",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.month = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.months, function (m, idx) {
                      return _c(
                        "option",
                        {
                          key: idx,
                          attrs: { disabled: !m.enabled },
                          domProps: { value: m.value + 1 },
                        },
                        [_vm._v(_vm._s(m.label))]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.year,
                        expression: "year",
                      },
                    ],
                    ref: "yearSelect",
                    staticClass: "yearselect",
                    attrs: { type: "number" },
                    domProps: { value: _vm.year },
                    on: {
                      blur: _vm.checkYear,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.year = $event.target.value
                      },
                    },
                  }),
                ])
              : _vm._t(
                  "month-title",
                  [
                    _c("div", { staticClass: "calendar-selector" }, [
                      _c(
                        "div",
                        {
                          staticClass: "calendar-selector__prev prev available",
                          attrs: { tabindex: "0" },
                          on: { click: _vm.prevMonthClick },
                        },
                        [_c("span")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "calendar-selector__month month" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.monthName) +
                              " " +
                              _vm._s(_vm.year) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "calendar-selector__next next available",
                          attrs: { tabindex: "0" },
                          on: { click: _vm.nextMonthClick },
                        },
                        [_c("span")]
                      ),
                    ]),
                  ],
                  null,
                  {
                    monthName: _vm.monthName,
                    year: _vm.year,
                    nextMonthClick: _vm.nextMonthClick,
                    prevMonthClick: _vm.prevMonthClick,
                  }
                ),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      [
        _c(
          "tr",
          [
            _vm.showWeekNumbers
              ? _c("th", { staticClass: "week" }, [
                  _vm._v(_vm._s(_vm.locale.weekLabel)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.locale.daysOfWeek, function (weekDay, idx) {
              return _c("th", { key: idx }, [_vm._v(_vm._s(weekDay))])
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm._l(_vm.calendar, function (dateRow, index) {
          return _c(
            "tr",
            { key: index },
            [
              _vm.showWeekNumbers && (index % 7 || index === 0)
                ? _c("td", { staticClass: "week" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$dateUtil.weekNumber(dateRow[0])) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(dateRow, function (date, idx) {
                return _c(
                  "td",
                  {
                    key: idx,
                    ref: _vm.dayRef(date),
                    refInFor: true,
                    class: _vm.dayClass(date),
                    attrs: { "data-date": date.toISOString().substring(0, 10) },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("dateClick", date)
                      },
                      mouseover: function ($event) {
                        return _vm.$emit("hoverDate", date)
                      },
                    },
                  },
                  [
                    _vm._t(
                      "date-slot",
                      [
                        _vm._v(
                          "\n        " + _vm._s(date.getDate()) + "\n      "
                        ),
                      ],
                      { date: date }
                    ),
                  ],
                  2
                )
              }),
            ],
            2
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }