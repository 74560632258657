<template>
    <div class="calendar-no-picker" :style="cssVars">
      <date-range-picker ref="noPicker"
          v-bind="options" v-model="selectedDates"
          :date-format="disablePriorDates"
          @toggle="onCalendarToggle"
          @start-selection="setFirstDate"
          @finish-selection="setEndDate"
      >
          <template v-slot:input="picker" style="border: none">
            <div class=""></div>
          </template>
          <template v-slot:prev-month-btn style="border: none">
            <div class=""></div>
          </template>
          <template v-slot:next-month-btn style="border: none">
            <div class=""></div>
          </template>
          <template v-slot:prev-month-title="slotProps" style="border: none">
            <div class="d-flex">
              <div class="month GFont_3_Extrabold flex-grow-1" style="text-align:left">
                {{ slotProps.monthName }} {{ slotProps.year }}
              </div>
            </div>
          </template>
          <template v-slot:next-month-title="slotProps" style="border: none">
            <div style="display: flex; align-items: center;">
              <div class="month GFont_3_Extrabold flex-grow-1" style="text-align:left">
                {{ slotProps.monthName }} {{ slotProps.year }}
              </div>
              <div style="flex: 1; display: flex; justify-content: flex-end; gap: 1rem;">
                <div class="GFont_3_Extrabold px-5" @click="slotProps.prevMonthClick" tabindex="0" style="font-size: 24px !important;">&lt;</div>
                <div class="mx-4 GFont_3_Extrabold" @click="slotProps.nextMonthClick" tabindex="0" style="font-size: 24px !important;">&gt;</div>
              </div>
            </div>
          </template>

          <template v-slot:footer style="border: none">
            <div class="cal_footer">
              <div class="cal_footer_dates center">
                <span class="GFont_6" v-if="selectedDates.startDate">
                  {{ selectedDates.startDate | dateShort }} -&gt;
                </span>
                <span class="GFont_6" v-if="selectedDates.endDate && !state.rangeSelectionStarted">
                  {{ selectedDates.endDate | dateShort }}
                </span>
              <span class="GFont_6" v-if="selectedDates.startDate && selectedDates.endDate && !state.rangeSelectionStarted">
                  ( {{ dayDiffNights }} )
                </span>
              </div>

              <button class="btn btn-primary" @click="onSelectedDates">Done</button>
            </div>
            
          </template>
      </date-range-picker>
    </div>
</template>
<script>

//import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import VueI18n from 'vue-i18n';
import DateRangePicker from '../../../vendor/js/libs/vue2-daterange-picker/src/index.js';
// import '../../../vendor/js/libs/vue2-daterange-picker/src/assets/daterangepicker.scss';

//import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
//import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import { submitLogEvent } from '../../utils/tracking';

import messages from '../common/messages';
import formatDate from '../filters/format-date';


export default {
  components: {
    DateRangePicker
  },
  i18n: new VueI18n({
    locale: 'en',
    messages,
  }),
  props: {
    startDate: {
      type: Date,
    },
    endDate: {
      type: Date,
    },
  },
  methods: {
    setFirstDate(dateSelected) {
      this.state.rangeSelectionStarted = true;
      this.selectedDates.startDate = dateSelected;
      this.selectedDates.endDate = dateSelected;
    },
    disablePriorDates (classes, date) {
      if (!classes.disabled) {
        if(this.state.rangeSelectionStarted){
          classes.disabled = date.getTime() <= this.selectedDates.startDate.getTime()
        }
      }
      return classes
    },
    setEndDate(dateSelected) {
      this.state.rangeSelectionStarted = false;
      this.selectedDates.endDate = dateSelected;
      submitLogEvent(`${this.$parent.$parent.$options.name}_setNewDates`, {'check_in': formatDate(this.selectedDates.startDate , 'YYYY-MM-DD'),
                                           'check_out': formatDate(this.selectedDates.endDate , 'YYYY-MM-DD')});
    },
    onCalendarToggle(isOpen){
      if(!isOpen){
          this.$refs['noPicker'].togglePicker(true);
      }
      this.$emit('calendar-opened', isOpen)
    },
    onSelectedDates(btn){
      if(!this.state.rangeSelectionStarted){
        this.$emit('dates-changed', this.selectedDates);
      }else{
        this.$emit('cancel-changes');
      }
      submitLogEvent(`${this.$parent.$parent.$options.name}_doneDates`,{})
    }
  },
  filters: {
    date(val) {
      return val ? formatDate(val,'ddd MMM DD, YYYY') : ''
    },
    dateShort(val) {
      return val ? formatDate(val,'ddd MMM DD') : ''
    }
  },
  data() {
    return {
      drpKey: 0,
      state: {
        rangeSelectionStarted:false,
      },
      windowHeight : undefined,
      options: {
        minDate: new Date(),
        //opens: 'right',
        appendToBody: false,
        ranges: false,
        autoApply: false,
        dateFormat:this.disablePriorDates,
        localeData: {
          direction: 'ltr',
          format: 'mmm dd',
          // separator: ' - ',
          // applyLabel: 'Apply chiche',
          // cancelLabel: 'Cancel chiche',
          // weekLabel: 'W',
          // customRangeLabel: 'Custom Range',
          // daysOfWeek: ['a', 'v', 'c', 'd', 'Thu', 'Fri', 'Sat'],
          // monthNames: ['Jan', 'x1', 'Mar', 'Apr', 'May', 'x', 'Jul', 'Aug', 'eee', 'x3', 'Nov', 'Dec'],
          // firstDay: 0
        }
      },
      selectedDates: {
        startDate: this.$props.startDate,
        endDate: this.$props.endDate,
      },
    }
  },
  computed: {
    cssVars () {
      return {
        "--cal_vh": `${this.windowHeight}px`,
      }
    },
    dayDiffNights() {
      let nights = moment(this.selectedDates.endDate, "YYYY-MM-DD").diff(
        moment(this.selectedDates.startDate, "YYYY-MM-DD"),
        "days"
      );
      if (nights > 1) {
        return `${nights} nights`;
      }
      return "1 night";
    },
    // selectedDaysCalendarRef(){
    //   const month = String(this.startDate.getMonth() + 1).padStart(2, '0');
    //   const day = String(this.startDate.getDate()).padStart(2, '0');
    //   return month + '-' + day
    // }
  },
  beforeMount() {
    //console.log(JSON.stringify([this.selectedDates, this.$props.startDate, this.$props.endDate]))
  },
  mounted() {
    let self = this;
    this.$nextTick(() => {
        self.selectedDates.startDate = self.$props.startDate;
        self.selectedDates.endDate = self.$props.endDate;

        //console.log(this.$refs['noPicker'])
        this.$refs['noPicker'].togglePicker(true);
        this.windowHeight = window.innerHeight;
        window.addEventListener("resize", (e) => {
            this.windowHeight = window.innerHeight;
        });
        //self.("td.active:first").scrollIntoView();
    });
    // submitLogEvent('open_modal_dates', '')
  },
  /*watch: {
    selectedDates: function (n, o) {
      this.$emit('dates-changed', n)
    }
  }*/
}
</script>
